import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
import PasswordStrengthBar from 'react-password-strength-bar';
import { User } from '../../../Classes/User';
import Banner from '../../../components/Banner/Banner';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Input from '../../../components/Input/input';
import { Loading } from '../../../components/Loading/Loading';
import { API_CheckQuestions, API_GetSecurityQuestions, API_RecoverPassword } from '../../../Services/ApiRoutes';
import { Encrypt } from '../../../Services/Crypto';
import { Fetch } from '../../../Services/Fetch';
import { Button } from '../Courses/Components/Button/Button';
import * as styles from './ForgotPassword.module.scss';

type SecurityQuestion = {
  Question: string;
  Answer: string;
  Number: number;
}

type ErrorMessage = {
  Message: string;
  Class: string;
}

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [user, setUser] = useState<User>({} as User);
  const [questions, setQuestions] = useState<SecurityQuestion[]>([] as SecurityQuestion[]);
  const [allowChangePassword, setAllowChangePassword] = useState<boolean>(false);
  const [captcha, setCaptcha] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<ErrorMessage>({} as ErrorMessage);
  const [passwordStength, setPasswordStength] = useState<number>(0);

  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setMessage("");
  }, [email]);

  const Submit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (email)
      await Fetch(API_GetSecurityQuestions, email).then((u: User) => {
        if (u.ID) {
          if (u.RecoveryQuestions.length === 3) {
            setQuestions(u.RecoveryQuestions.map((q, i) => {
              return {
                Question: q,
                Answer: "",
                Number: i
              } as SecurityQuestion
            }));
            setEmail("");
            setUser(u);
          } else {
            setMessage("This account has not been set up to recover");
          }
        } else {
          setMessage("The Email provided was incorrect");
        }
      }).catch((ex) => {
        setMessage(ex.message);
      });
    else
      setMessage("Please fill in the required field");
    setLoading(false);
  }

  const CheckQuestions = async () => {
    setLoading(true);

    var ques: SecurityQuestion[] = JSON.parse(JSON.stringify(questions));
    ques = ques.map(q => {
      q.Answer = Encrypt(q.Answer.toLowerCase());
      return q;
    })

    await Fetch(API_CheckQuestions, { UserID: user.ID, Questions: ques }).then((success: boolean) => {
      if (success) {
        setAllowChangePassword(true);
      } else {
        setMessage("One of the answers you provided was incorrect");
        setAllowChangePassword(false);
      }
    }).catch((ex) => {
      setMessage(ex.message);
    });
    setLoading(false);
  }

  const onChange = (value: any) => {
    setCaptcha(value);
  }

  const ChangePassword = async (e: any) => {
    e.preventDefault();
    let message: ErrorMessage = { Class: "Error" } as ErrorMessage;

    if (newPassword && confirmPassword) {
      if (newPassword === confirmPassword) {
        if (user.ID) {
          let success: boolean = await Fetch(API_RecoverPassword, { Password: Encrypt(newPassword), Email: user.Email, ID: user.ID } as User);

          if (success) {
            message.Message = "Password Successfully Changed";
            message.Class = "Success";
            setNewPassword("");
            setConfirmPassword("");
          }
        }
      } else {
        message.Message = "Passwords Do Not Match";
      }
    } else {
      message.Message = "Please Ensure All Fields Are Filled In";
    }

    setPasswordError(message);
  }

  return (<>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>PrimeHR :: Recover Password</title><meta charSet='utf-8' />
    </Helmet>

    <Banner />

    <Breadcrumb Trail={[
      { To: "", Text: "Recover Password" }
    ]} />

    <div className={styles.Login}>
      {allowChangePassword ? <form className={`${styles.Form}`} onSubmit={(e) => ChangePassword(e)}>
        <div className={`${styles.FormInner}`}>
          <h1>New Password</h1>

          {passwordError.Class === "Success" ? <></> : <>
            <Input Required Placeholder="" Type="password" Value={newPassword} Label="New Password" OnChange={(v) => setNewPassword(v)} />
            <div className={styles.PasswordStength}>
              <PasswordStrengthBar password={newPassword} onChangeScore={(e) => setPasswordStength(e)} />
            </div>
            <Input Required Placeholder="" Type="password" Value={confirmPassword} Label="Confirm Password" OnChange={(v) => setConfirmPassword(v)} />
          </>}

          {
            passwordError.Message ? <div className={`${styles.Message} ${styles[passwordError.Class]}`}>
              <p>{passwordError.Message}</p>
            </div> : <></>
          }

          {passwordError.Class === "Success" ? <></> : <button type="submit" className={`${styles.Button}`} disabled={!newPassword || !confirmPassword || passwordStength < 3}>
            Change Password
          </button>}
        </div>
      </form>
        : <form className={styles.Form} onSubmit={(e) => Submit(e)}>
          <div className={`${styles.FormInner}`}>
            {loading ? <Loading /> : user.ID ? <>
              <h1>Security Questions</h1>

              {
                questions.map(que => {
                  return <div className={styles.Input}>
                    <span>{que.Question}</span>
                    <Input Required Placeholder="" Type="text" Value={que.Answer} Label="..." OnChange={(v) => setQuestions(questions.map(q => {
                      if (q.Number === que.Number)
                        q.Answer = v;
                      return q;
                    }))} />
                  </div>
                })
              }

              {message ? <div className={styles.Message}>
                {message}
              </div> : <></>}

              <Button Type="submit" OnClick={() => CheckQuestions()}>
                Continue
                <i>
                  <FontAwesomeIcon icon={faArrowRight} />
                </i>
              </Button>
            </> : <>
              <h1>Recover Password</h1>
              <Input Required Placeholder="" Type="email" Value={email} Label="Email" Icon={faEnvelope} OnChange={(v) => setEmail(v)} />

              {message ? <div className={styles.Message}>
                {message}
              </div> : <></>}

              <div className="capcha">
                <ReCAPTCHA
                  onExpired={() => { setMessage("RECAPTCHA has expired, please try again"); }}
                  onErrored={() => { setMessage("An Error has Occurred on the RECAPTCHA, please try again"); }}
                  sitekey="6Lf-XiQaAAAAAI5mHpzBUc6vtyvEWTS17PLcGnWT" onChange={e => { onChange(e); }} />
              </div>

              <Button Type="submit" Disabled={!email || !captcha}>
                Continue
                <i>
                  <FontAwesomeIcon icon={faArrowRight} />
                </i>
              </Button>
            </>}
          </div>
        </form>}
    </div>
  </>
  );
};

export default ForgotPassword;
